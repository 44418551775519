import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters({
			qtyCart: 'qtyCart'
		})
	},

	created() {
		if(!this.qtyCart(null)) {
			this.$router.push({name: 'HomeIndex'})
		}
	},
}