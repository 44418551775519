<script>
import stepMixin from '../../mixins/stepMixin'
import checkCart from '../../mixins/checkCart'

import { mapActions, mapGetters } from 'vuex'
import CarouselComponent from '../../components/CarouselComponent'

export default {
	mixins: [stepMixin, checkCart],
	components: {
		CarouselComponent,
		ProductCard: () => import(/* webpackChunkName: "ProductCard" */ `../../components/ProductCard`)
	},
	data() {
		return {}
	},

	computed: {
		year() {
			let date = new Date()

			return date.getFullYear()
		},
		...mapGetters({
			sides: 'sides'
		})
	},

	methods: {
		...mapActions({
			getMenu: 'getMenu'
		})
	},

	mounted() {
		if(!this.sides.length) {
			this.getMenu('sides')
		}
	}
}
</script>
<template>
	<div class="section">
		<div class="alert">
			<div class="section__container py-2">
				<h5 class="text-center m-0 text-bold">Selecciona tu complemento - Paso 2/4</h5>
			</div>
		</div>
		<div class="d-none d-md-block">
			<CarouselComponent :size="sides.length">
				<div class="row" >
					<div class="carousel__item col-12 px-3 py-3" 
						:class="'col-md-7 col-lg-3 col-xl-2'" 
						v-for="(item, index) in sides" 
						:key="`hotdog-${index}-${item.id}`">
						<ProductCard 
							:id="item.idProducto" 
							:picture="item.imagen" 
							:title="item.nombreProducto" 
							:description="item.descripcion"
							:price="item.precio"
							type="sides"
							/>
					</div>
				</div>
			</CarouselComponent>
		</div>
		<div class="d-block d-md-none">
			<div class="carousel carousel--no-mobile">
				<div class="carousel__container" >
					<div class="carousel__well" :class="{'carousel__well--grid': sides.length > 7}">
						<div class="carousel__item col-12 px-3 py-3" 
							:class="{
								'col-12': sides.length > 7,
								'col-sm-5': index && sides.length > 7,
								'col-sm-10': !index && sides.length > 7,
								'col-md-4 col-lg-3 col-xl-2': sides.length <= 7,

							}" 
							v-for="(item, index) in sides" 
							:key="`hotdog-${index}-${item.id}`">
							<ProductCard 
								:id="item.idProducto" 
								:picture="item.imagen" 
								:title="item.nombreProducto" 
								:description="item.descripcion"
								:price="item.precio"
								type="sides"
								/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="v-nav">
			<div class="container d-none d-md-block p-3">
				<div class="d-flex justify-content-between">
					<div class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2">
						<router-link :to="{name: prevStep}" class="btn btn--bold btn--block">
							Atrás
						</router-link>
					</div>
					<div class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2">
						<router-link :to="{name: nextStep}" class="btn btn--primary btn--bold btn--block">
							Siguiente
						</router-link>
					</div>
				</div>
			</div>
			<footer class="footer">
				<div class="container text-center">
					Todos Los Derechos Reservados {{year}} Panshito®.
				</div>
			</footer>
		</div>
	</div>
</template>
<style lang="scss" >
	.alert{
		background-color: #FE2917;
		color: #fff;
		h5{
			font-size: 0.9rem;
		}
	}

	.text-bold{
		font-weight: 700;
	}

	
</style>