<script>
export default {
	props: {
		size: {
			type: Number,
		}
	},
	data() {
		return {
			currentOffset: 0,
			windowSize: 1,
			paginationFactor: 0,
		}
	},
	computed: {
		atEndOfList() {
			return this.currentOffset <= (this.paginationFactor * -1) * (this.size - this.windowSize);
		},
		atHeadOfList() {
			return this.currentOffset === 0;
		},
	},
	methods: {
		moveCarousel(direction) {
			this.calcFactor()
			// Find a more elegant way to express the :style. consider using props to make it truly generic
			if (direction === 1 && !this.atEndOfList) {
				this.currentOffset -= this.paginationFactor * this.windowSize;
			} else if (direction === -1 && !this.atHeadOfList) {
				this.currentOffset += this.paginationFactor * this.windowSize;
				if(this.currentOffset > 0) {
					this.currentOffset = 0
				}
			}
		},

		calcFactor() {
			let $item = this.$el.querySelector('.carousel__item')

			if(!$item) return 
			let factorPage = $item.offsetWidth
			let $carousel = this.$el.querySelector('.card-carousel-cards')
			let widthCarousel = $carousel.offsetWidth
			let cardInPage = Math.floor(widthCarousel/factorPage)
			this.windowSize = cardInPage
			this.paginationFactor = factorPage
		},

		reset() {
			this.currentOffset = 0
			this.calcFactor()
		}
	},

	mounted() {
		this.calcFactor()

		window.addEventListener('resize', this.reset)
	},


	beforeDestroy(){
		window.removeEventListener('resize', this.reset)
	},

	updated() {
		this.calcFactor()
	}
}
</script>

<template>
	<div class="carousel carousel--no-mobile card-carousel-wrapper flex-wrap">
	
    <div class="card-carousel">
		
    	<div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList">
			<span class=" icon-left"></span>
		</div>
      	<div class="card-carousel--overflow-container">
			<div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')' }">
				<slot></slot>
			</div>
      	</div>
    	<div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList">
			<span class=" icon-right"></span>
		</div>
    </div>
  </div>
</template>